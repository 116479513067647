import React from "react"
import Layout from "hoc/Layout"
import { graphql } from "gatsby"
import PostHeader from "../containers/PostDetails/PostHeader/PostHeader"
import { Container } from "@mui/material"
import PostContent from "../containers/PostDetails/PostContent/PostContent"

const BlogPostTemplate = ({ data }) => {
  const postData = data.contentfulPostBloga

  const {
    title,
    mainImage: {
      file: { url },
    },
  } = postData
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "Blog",
      link: "/blog",
    },
    `${title}`,
  ]

  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle={`${title}`}
      containerSize={"xl"}
      seoTitleDescription={"Blog Medira"}
      seoThumbnail={`https:${url}`}
    >
      <PostHeader {...postData} />
      <Container maxWidth="md">
        <PostContent {...postData} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query BlogPostDetails($contentful_id: String) {
    contentfulPostBloga(contentful_id: { eq: $contentful_id }) {
      author {
        name
        surname
        position
        avatar {
          file {
            url
          }
        }
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            gatsbyImageData(
              backgroundColor: "transparent"
              placeholder: BLURRED
              width: 852
            )
          }
        }
      }
      createdAt(formatString: "DD.MM.yyyy")
      mainImage {
        gatsbyImageData(
          backgroundColor: "transparent"
          quality: 90
          placeholder: BLURRED
        )
        file {
          url
        }
        title
      }
      title
    }
  }
`

export default BlogPostTemplate
